// deps
import { useEffect, useState } from "react";

// helpers
import { roomHasLastSeenRoom } from "@cloudspire/legacy-resources/src/helpers/room";

function HasSeenRoom(props) {
  const { preventRoomReference, roomToAdd, children } = props;

  const [hasLasSeenRoom, setHasLasSeenRoom] = useState(false);

  useEffect(
    function () {
      setHasLasSeenRoom(
        roomHasLastSeenRoom({ preventRoomReference, roomToAdd })
      );
    },
    [roomToAdd, preventRoomReference]
  );

  return <>{hasLasSeenRoom && children}</>;
}

export default HasSeenRoom;
